import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from './globals';
import { promise } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class AjaxService {

    constructor(
        private http: HttpClient,
        private globals: Globals,
    ) { }

    // tslint:disable-next-line: typedef
    get(cmd: string, par: {} = {} ) {
        return new Promise(resolve => {
            this.http.post(this.globals.url, {
                db: this.globals.db,
                usr: this.globals.usr,
                pwd: this.globals.pwd,
                pcnr: this.globals.pcnr,
                app: this.globals.app,
                ver: this.globals.version,
                cmd,
                par
            }).subscribe(
                async data => {
                    resolve(data);
                }
            );
        });
    }

}
