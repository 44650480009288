import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { interval } from 'rxjs';

import { Globals } from '../globals';
import { AjaxService } from '../ajax.service';

@Component({
    selector: 'app-lift',
    templateUrl: './lift.component.html',
    styleUrls: ['./lift.component.scss']
})
export class LiftComponent implements OnInit {
    currTab = 'data';
    liftnr = '';
    bestnr = '';
    objectdata = [];
    objectprop = [];
    sessID = '';
    statusCols = 2;
    liftstatus = [];
    subscription: any;

    constructor(
        public globals: Globals,
        public router: Router,
        private route: ActivatedRoute,
        private ajax: AjaxService,
    ) { }

    ngOnInit(): void {
        this.route.params.subscribe(pars => {
            this.liftnr = pars.liftnr;
            this.sessID = pars.sessID;
            if (!this.globals.login && this.sessID) {
                console.log('login based on session ID');
                this.globals.usr = this.liftnr;
                this.globals.pwd = this.sessID;
                this.ajax.get('login')
                .then((data: any) => {
                    if (data.login.login) {
                        this.globals.login = true;
                        this.globals.lvl = data.login.lvl;
                        this.globals.grp = data.login.grp;
                        this.loadInitial();
                    } else {
                        this.globals.login = false;
                        this.globals.pwd = '';
                        alert('Invalid credentials!');
                        this.router.navigate(['home']);
                    }
                });
            } else {
                this.loadInitial();
            }
        });

    }

    loadInitial(): void {
        if (!this.globals.login) {
            this.router.navigate(['home']);
        } else {
            this.ajax.get('Record', {
                table: 'object',
                fields: 'ID, Omschr, Adres, Analyzer',
                field: 'ID',
                value: this.liftnr
            }).then((data: any) => {
                if (!data.login.login) {
                    this.router.navigate(['home']);
                } else {
                    this.objectdata = data.result;
                    if (this.objectdata['Analyzer']) {
                        this.currTab = 'status';
                        this.bestnr = this.objectdata['Analyzer'];
                        this.statusCols = Math.min(Math.max(Math.floor(window.innerWidth / 200), 1), 3);
                    } else {
                        this.bestnr = '';
                    }
                    this.ajax.get('Table', {
                        table: 'object_prop',
                        fields: 'VarName, Value, SerialNr',
                        where: 'ObjectRef="' + this.liftnr + '"',
                        order: 'VarName'
                    }).then((data2: any) => {
                        if (!data2.login.login) {
                            this.router.navigate(['home']);
                        } else {
                            this.objectprop = data2.result;
                        }
                    });
                    const observable = interval( 1.0 * 1000);
                    this.subscription = observable.subscribe(x => this.loadStatus());

                }
            });
        }
    }

    onResize(event): void {
        this.statusCols = Math.min(Math.max(Math.floor(event.target.innerWidth / 200), 1), 3);
        console.log('w=', event.target.innerWidth, 'c', this.statusCols);
    }

    loadStatus(): void {
        if (this.currTab === 'status') {
            this.ajax.get('lift_status', {
                lift: this.bestnr
            }).then((data: any) => {
                if (!data.login.login) {
                    this.router.navigate(['home']);
                } else {
                    this.liftstatus = data.result;
                }
            });
        }
    }

}
