<button *ngIf="globals.lvl>-1" (click)="globals.login=false; router.navigate(['home'])">Logout</button>
<button *ngIf="globals.lvl>-1" (click)="router.navigate(['liften'])">Overzicht Liften</button>
<div class='tabbar'>
    <div class='tablabel' (click)="currTab='status'" [ngClass]="{'tabactive' : currTab=='status'}" *ngIf="objectdata && objectdata['Analyzer']">
        Status besturing
    </div>
    <div class='tablabel' (click)="currTab='log'" [ngClass]="{'tabactive' : currTab=='log'}" *ngIf="objectdata && objectdata['Analyzer']">
        Log besturing
    </div>
    <div class='tablabel' (click)="currTab='data'" [ngClass]="{'tabactive' : currTab=='data'}">
        Liftgegevens
    </div>
    <div class='tablabel' (click)="currTab='doc'" [ngClass]="{'tabactive' : currTab=='doc'}">
        Documenten
    </div>
</div>
<div class='tabcontainer'>
    <div *ngIf="currTab=='status'" class='tabcontent'>
        <mat-grid-list [cols]="statusCols" (window:resize)="onResize($event)">
            <mat-grid-tile>
                <div class="topleft">
                    <b>Veiligheidslijn</b>
                    <table *ngIf="liftstatus">
                        <tr *ngFor="let sign of liftstatus.safety | keyvalue">
                            <td>
                                <mat-icon *ngIf="sign.value" class="ledgreen">noise_control_off</mat-icon>
                                <mat-icon *ngIf="!sign.value" class="ledred">close</mat-icon>
                            </td>
                            <td>{{sign.key}}</td>
                        </tr>
                    </table>
                </div>
            </mat-grid-tile>
            <mat-grid-tile>
                <div class="topleft">
                    <b>Status</b>
                    <table *ngIf="liftstatus">
                        <tr><td align="center"><b>{{liftstatus.pos}}</b></td><td>Stopplaats</td></tr>
                        <tr *ngFor="let sign of liftstatus.req | keyvalue">
                            <td>
                                <mat-icon *ngIf="sign.value" class="ledgreen">noise_control_off</mat-icon>
                                <mat-icon *ngIf="!sign.value" class="ledred">close</mat-icon>
                            </td>
                            <td>{{sign.key}}</td>
                        </tr>
                    </table>
                </div>
            </mat-grid-tile>
            <mat-grid-tile>
                <div class="topleft">
                    <b>Positie</b>
                    <table *ngIf="liftstatus.stoppos">
                        <tr>
                            <td align="center" class="schachtkop"><div [ngClass]="liftstatus.posmm>liftstatus.stoppos[0].height+100 ? 'cabin' : 'none'">.</div></td>
                            <td></td>
                        </tr>
                        <ng-container *ngFor="let p of liftstatus.stoppos; let i = index;">
                            <tr>
                                <td align="center" class="schachtstop"><div [ngClass]="liftstatus.posmm>p.height-100 && liftstatus.posmm<p.height+100 ? 'cabin' : 'none'">.</div></td>
                                <td>{{p.pos}}</td><td align="right">{{p.height}}</td>
                            </tr>
                            <tr>
                                <td align="center" [ngClass]="i>=liftstatus.posnr-1 ? 'schachtput' : 'schacht'">
                                    <div [ngClass]="((i>=liftstatus.posnr-1) ? true : (liftstatus.posmm>liftstatus.stoppos[i+1].height+100)) && liftstatus.posmm<p.height-100 ? 'cabin' : 'none'">.</div>
                                </td>
                                <td></td>
                            </tr>
                        </ng-container>
                        <tr><td>{{liftstatus.posmm}}</td></tr>
                    </table>
                </div>
            </mat-grid-tile>
            <mat-grid-tile>
                <div class="topleft">
                    <b>Commando's</b>
                </div>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
    <div *ngIf="currTab=='data'" class='tabcontent'>
        <table *ngIf="objectdata">
            <tr><td>Lift Nr.</td><td>{{liftnr}}</td></tr>
            <tr><td>Locatie</td><td>{{objectdata.Omschr}}</td></tr>
            <tr><td>Adres</td><td>{{objectdata.Adres}}</td></tr>
        </table>
        <hr>
        <table *ngIf="objectprop">
            <tr><th></th><th>Waarde</th><th>Serie nr.</th></tr>
            <tr *ngFor="let prop of objectprop"><td>{{prop.VarName}}</td><td>{{prop.Value}}</td><td>{{prop.SerialNr}}</td></tr>
        </table>
    </div>
</div>
