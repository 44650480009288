import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Globals } from '../globals';
import { AjaxService } from '../ajax.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    showpwd = false;
    inProgress = false;

    constructor(
        public globals: Globals,
        private router: Router,
        private ajax: AjaxService,
    ) { }

    ngOnInit(): void {
        console.log('Home page opened');
    }

    login(): void {
        console.log('Login');
        this.inProgress = true;
        this.showpwd = false;
        this.ajax.get('login')
        .then((data: any) => {
            if (data.login.login) {
                this.globals.login = true;
                this.globals.lvl = data.login.lvl;
                this.globals.grp = data.login.grp;
                this.router.navigate(['liften']);
                this.inProgress = false;
            } else {
                this.inProgress = false;
                alert('Invalid credentials!');
            }
        });

    }

}
