import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MaterialModule } from './material.module';
import { Globals } from './globals';
import { AjaxService } from './ajax.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LiftComponent } from './lift/lift.component';
import { HomeComponent } from './home/home.component';
import { LiftenComponent } from './liften/liften.component';

@NgModule({
  declarations: [
    AppComponent,
    LiftComponent,
    HomeComponent,
    LiftenComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    MaterialModule,
    FormsModule,
    BrowserAnimationsModule,
  ],
  providers: [
    Globals,
    AjaxService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
