import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Globals } from '../globals';
import { AjaxService } from '../ajax.service';

@Component({
    selector: 'app-liften',
    templateUrl: './liften.component.html',
    styleUrls: ['./liften.component.scss']
})
export class LiftenComponent implements OnInit {
    liften = [];

    constructor(
        public globals: Globals,
        public router: Router,
        private ajax: AjaxService,
    ) { }

    ngOnInit(): void {
        if (!this.globals.login) {
            this.router.navigate(['home']);
        } else {
            this.ajax.get('Table', {
                table: 'object',
                fields: 'ID, Omschr, Adres',
                where: 'ID>""',
                order: 'ID'
            }).then((data: any) => {
                if (!data.login.login) {
                    this.router.navigate(['home']);
                } else {
                    this.liften = data.result;
                }
            });
        }
    }

    goto(liftID): void {
        this.router.navigate(['lift/' + liftID]);
    }

}
