<form class="login">
    <table cellspacing="20">
        <tr>
            <td>
                Username
            </td>
            <td>
                <mat-form-field appearance="outline">
                    <input matInput [(ngModel)]="globals.usr" name="username" required autofocus>
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td>
                Password
            </td>
            <td>
                <mat-form-field appearance="outline">
                    <input matInput [(ngModel)]="globals.pwd" [type]="showpwd ? 'text' : 'password'" name="password"
                        required>
                </mat-form-field>
            </td>
            <td>
                <mat-icon (click)="showpwd=!showpwd">{{showpwd ? 'visibility' : 'visibility_off'}}</mat-icon>
            </td>
        </tr>
        <tr>
            <td></td>
            <td>
                <button *ngIf="!inProgress" mat-raised-button (click)="login()" color="primary">Login</button>
            </td>
            <td>
                <small>v. {{globals.version}}</small>
            </td>
        </tr>
    </table>
</form>
