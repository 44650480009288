import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
    version = '0.0.1';
    login = false;
    db = 'liproliften';
    baseurl = 'https://cloud.lisaas.com/' + this.db;
    url = this.baseurl + '/api/get.php';
    urlDoc = this.baseurl + '/doc.php?src=';
    usr = '';
    pwd = '';
    lvl = -1;
    grp = '';
    pcnr = '';
    app = 'Liften';
    title = 'MR-Liften: Liftboek';
    stdTitle = this.title;

    constructor() {
        let uuid = localStorage.getItem('uuid');
        if (!uuid) {
          uuid = String(Math.floor((Math.random() * 1000000000) + 1));
          localStorage.setItem('uuid', uuid);
        }
        this.pcnr = uuid;
        console.log('pcnr: ', this.pcnr);
    }
}
